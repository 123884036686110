import { AgentHubEventsProvider, AgentHubEventsProviderProps } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { Button } from 'antd';
import { PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import environmentConfig from '../../environmentConfig';
import { selectAuthInfo } from '../../redux/authSlice';
import { NotificationContext } from './NotificationProvider';

const { apiBaseUrl } = environmentConfig;
const agentHubUrl = new URL('/blender/agentHub', apiBaseUrl).href;

/**
 * Serves as a wrapper for the AgentHubEventsProvider.
 *
 * **Provider dependencies:**
 * - (Store) Provider
 * - NotificationProvider
 * - TranslationProvider
 */
export default function AgentHubEventsProviderWrapper({ children }: PropsWithChildren): ReactElement {
  const notificationApi = useContext(NotificationContext);

  const { token } = useSelector(selectAuthInfo);
  const { t } = useTranslation();

  const signalRConnectionEventHandlers = useMemo<NonNullable<AgentHubEventsProviderProps['signalRConnectionEventHandlers']>>(
    () => ({
      onConnectFailed: () => notificationApi.error({
        key: 'aui-connectionFailed',
        message: t('notification.connectionRestoreFailed.title'),
        description: t('notification.connectionRestoreFailed.content'),
        btn: <Button type="primary" size="small" onClick={() => location.reload()}>{t('notification.connectionRestoreFailed.refreshButtonText')}</Button>,
      }),
      onConnectionLost: () => notificationApi.warning({
        key: 'aui-connectionLost',
        message: t('notification.connectionLost.title'),
        description: t('notification.connectionLost.content'),
      }),
      onConnectionRestored: () => {
        notificationApi.destroy('aui-connectionLost');
        notificationApi.success({
          key: 'aui-connectionRestored',
          role: 'status',
          message: t('notification.connectionRestored.title'),
          description: t('notification.connectionRestored.content'),
        });
      },
      onConnectionRestoreFailed: () => {
        notificationApi.destroy('aui-connectionLost');
        notificationApi.error({
          key: 'aui-connectionRestoreFailed',
          message: t('notification.connectionRestoreFailed.title'),
          description: t('notification.connectionRestoreFailed.content'),
          btn: <Button type="primary" size="small" onClick={() => location.reload()}>{t('notification.connectionRestoreFailed.refreshButtonText')}</Button>,
        });
      },
    }),
    [notificationApi, t],
  );

  return (
    <AgentHubEventsProvider
      agentHubUrl={agentHubUrl}
      token={token}
      signalRConnectionEventHandlers={signalRConnectionEventHandlers}
    >
      {children}
    </AgentHubEventsProvider>
  );
}